/* eslint max-lines: ["error", {"max": 700}] */
export const COPY = {
    LOGIN_SIGNUP: {
        EN: "Login/Sign up",
        ES: "Iniciar Sesión/Crear Cuenta",
    },
    LOGOUT: {
        EN: "Logout",
        ES: "Cerrar Sesión",
    },
    COMPANY_INFORMATION: {
        EN: "Company Information",
        ES: "Información de la Compañía",
    },
    COMPANY_NAME: {
        EN: "Company Name",
        ES: "Nombre de la Compañía",
    },
    HEADQUARTERS_ADDRESS: {
        EN: "Headquarters Address",
        ES: "Dirección de la Compañía",
    },
    COMPANY_PHONE: {
        EN: "Company Phone",
        ES: "Teléfono de la Compañía",
    },
    COMPANY_WEBSITE: {
        EN: "Company Website",
        ES: "Página Web de la Compañía",
    },
    SAVE_AND_CONTINUE: {
        EN: "Save and Continue",
        ES: "Guardar y Continuar",
    },
    DASHBOARD: {
        EN: "Dashboard",
        ES: "Tablero",
    },
    ACCOUNT: {
        EN: "Account",
        ES: "Mi Cuenta",
    },
    ACCOUNT_THANK_YOU: {
        EN: "Thank you for creating a BoomNation hiring account",
        ES: "Gracias por crear una cuenta en BoomNation",
    },
    TELL_US_ABOUT_YOUR_COMPANY: {
        EN: "Next, please tell us about your company",
        ES: "Ahora, por favor cuéntanos de tu compañía",
    },
    MY_JOB_POSTINGS: {
        EN: "My Job Postings",
        ES: "Mis Clasificados",
    },
    CURRENT_JOB_POSTINGS: {
        EN: "Current Jobs Postings",
        ES: "Clasificados Activos",
    },
    ARCHIVED_JOB_POSTINGS: {
        EN: "Archived Job Postings",
        ES: "Clasificados Archivados",
    },
    JOB_TITLE: {
        EN: "Job Title",
        ES: "Título de trabajo",
    },
    DATE_POSTED_SCHEDULED: {
        EN: "Date Posted/Scheduled",
        ES: "Fecha de creación",
    },
    STATUS: {
        EN: "Status",
        ES: "Estado",
    },
    ROWS_PER_PAGE: {
        EN: "Rows per page",
        ES: "Hileras por página",
    },
    OF: {
        EN: "of",
        ES: "de",
    },
    MORE_THAN: {
        EN: "more than",
        ES: "más que",
    },
    JOB_ERROR: {
        EN: "An error occurred when trying to get jobs",
        ES: "Un error ocurrió tratando de descargar los trabajos",
    },
    CREATE_ERROR: {
        EN: "An error occurred when trying to create. Please try again",
        ES: "Un error ocurrió tratando de descargar los trabajos. Por favor trate de nuevo",
    },
    COMPANY_CREATED: {
        EN: "Company Created",
        ES: "Compañía Creada",
    },
    ACCOUNT_ERROR: {
        EN: "There was an error loading account information",
        ES: "Error descargando la información de la compañía",
    },
    WELCOME_TO_BOOMNATION: {
        EN: "Welcome to BoomNation",
        ES: "Bienvenido a BoomNation",
    },
    HOME: {
        EN: "Home",
        ES: "Página principal",
    },
    EXPIRES: {
        EN: "Expires",
        ES: "Caduca",
    },
    TRADES_AND_ENDORSEMENTS: {
        EN: "Trades & Endorsements",
        ES: "Comercios y Patrocinios",
    },
    EXPERIENCE: {
        EN: "Experience",
        ES: "Experiencia",
    },
    EDUCATION: {
        EN: "Education",
        ES: "Educación",
    },
    RESUME: {
        EN: "Resume",
        ES: "El Currículum",
    },
    RECOMMENDATIONS: {
        EN: "Recommendations",
        ES: "Recomendaciones",
    },
    CERTIFICATIONS: {
        EN: "Certifications",
        ES: "Certificaciones",
    },
    VACCINATION_RECORD: {
        EN: "Vaccination Record",
        ES: "Registro de vacunación",
    },
    INTERESTED: {
        EN: "Interested",
        ES: "Interesado",
    },
    VACCINATED: {
        EN: "Vaccinated",
        ES: "Vacunado",
    },
    IN_LIST: {
        EN: "in List",
        ES: "en Lista",
    },
    CANDIDATE_SUCCESSFULLY_REMOVED: {
        EN: "Candidate successfully removed",
        ES: "Candidato eliminado con éxito",
    },
    MESSAGES: {
        EN: "Messages",
        ES: "Mensajes",
    },
    MOVE: {
        EN: "Move",
        ES: "Mover",
    },
    ADD_LABEL: {
        EN: "Add label",
        ES: "Añadir etiqueta",
    },
    ADD_TO_FAVORITES: {
        EN: "Add to Favorites",
        ES: "Agregar a los Favoritos",
    },
    REMOVE: {
        EN: "Remove",
        ES: "Eliminar",
    },
    UPLOADING_FILES: {
        EN: "Uploading files...",
        ES: "Cargando archivo...",
    },
    PUBLISH_AGGREEMENT: {
        EN: "By publishing you agree to the",
        ES: "Al publicar usted acepta el",
    },
    JOB_POSTING_AGREEMENT: {
        EN: "job posting agreement",
        ES: "acuerdo de publicación de trabajo",
    },
    TO: {
        EN: "to",
        ES: "a",
    },
    ACTIONS: {
        EN: "Actions",
        ES: "Acciones",
    },
    ADD_PEOPLE: {
        EN: "Add people",
        ES: "Añadir personas",
    },
    DELETE: {
        EN: "Delete",
        ES: "Borrar",
    },
    EDIT: {
        EN: "Edit",
        ES: "Editar",
    },
    DUPLICATE: {
        EN: "Duplicate",
        ES: "Duplicado",
    },
    GET_URL: {
        EN: "Get Url",
        ES: "Obtener Url",
    },
    GET_QR_CODE: {
        EN: "Get QR code",
        ES: "Obtener QR code",
    },
    LAUNCH_BOOMNATION: {
        EN: "LAUNCH BOOMNATION",
        ES: "ABRE BOOMNATION",
    },
    BRIDGE_THE_GAP: {
        EN: "Start bridging the gap between your company and skilled trade workers",
        ES: "Comienza a cerrar el espacio entre tu empresa y los trabajadores calificados",
    },
    SETTINGS: {
        EN: "Settings",
        ES: "Ajustes",
    },
    TEMPLATES: {
        EN: "Templates",
        ES: "Plantillas",
    },
    DELETE_TEMPLATE: {
        EN: "Delete Template",
        ES: "Eliminar Plantilla",
    },
    CHOOSE_A_TEMPLATE: {
        EN: "Choose a template",
        ES: "Elige una plantilla",
    },
    START_FROM_SCRATCH: {
        EN: "Start from scratch",
        ES: "Empezar desde el principio",
    },
    START_BUILDING_CUSTOM_CARD: {
        EN: "Start building your custom card.",
        ES: "Comience a construir su tarjeta personalizada.",
    },
    USE_A_TEMPLATE: {
        EN: "Use a template",
        ES: "Usa una plantilla",
    },
    CHOOSE_A_PREMADE_TEMPLATE: {
        EN: "Choose a premade template as a place to start. Edit or delete as needed.",
        ES: "Elija una plantilla prefabricada como punto de partida. Edite o elimine según sea necesario.",
    },
    ARE_YOU_SURE_YOU_WANT_TO_DELETE: {
        EN: "Are you sure you want to delete",
        ES: "¿Estás seguro de que quieres eliminar",
    },
    DELETE_FORM: {
        EN: "Delete Form",
        ES: "Eliminar Formulario",
    },
    FORMS: {
        EN: "Forms",
        ES: "Formularios",
    },
    SAVE_AS_TEMPLATE: {
        EN: "Save as template",
        ES: "Guardar como plantilla",
    },
    COMPANY_BULLETIN: {
        EN: "Company Bulletin",
        ES: "Boletín de la Empresa",
    },
    PROJECT_BULLETIN: {
        EN: "Project Bulletin",
        ES: "Boletín del Proyecto",
    },
    BULLETIN_POST: {
        EN: "Bulletin Post",
        ES: "Publicación de boletín",
    },
    FORM: {
        EN: "Form",
        ES: "Formulario",
    },
    SAVE: {
        EN: "Save",
        ES: "Ahorrar",
    },
    CANCEL: {
        EN: "Cancel",
        ES: "Cancelar",
    },
    EDIT_CARD_TEMPLATE: {
        EN: "Edit card template",
        ES: "Editar plantilla de tarjeta",
    },
    YOU_HAVE_BEEN_INVITED_TO: {
        EN: "You've been invited to",
        ES: "Has sido invitado a",
    },
    LONGER_MESSAGES_MAY_AFFECT_DELIVERY_RATES: {
        EN: "Longer messages may affect delivery rates",
        ES: "Mensajes más largos podrian afectar las tasas de entrega",
    },
    FORM_SUCCESS_ACCOUNT: {
        EN: "Account Information has been updated!",
        ES: "¡La información de la cuenta ha sido actualizada!",
    },
    FORM_SUCCESS_COMPANY: {
        EN: "Company Page has been updated!",
        ES: "¡La página de la compañía ha sido actualizada!",
    },
    FORM_SUCCESS_PERMISSIONS: {
        EN: "Invitations sent for new members",
        ES: "Invitaciones enviadas para nuevos miembros",
    },
    FORM_SUCCESS_INVITE_TO_JOB: {
        EN: "Candidate successfully invited!",
        ES: "¡Candidato invitado con éxito!",
    },
    FORM_SUCCESS_CANDIDATE_REJECTION: {
        EN: "Candidate successfully rejected!",
        ES: "¡Candidato rechazado con éxito!",
    },
    FORM_SUCCESS_IMPORT_CSV_JOBS: {
        EN: "Jobs successfully imported!",
        ES: "¡Trabajos importados con éxito!",
    },
    FORM_SUCCESS_SORT_CANDIDATES: {
        EN: "Candidates successfully sorted!",
        ES: "¡Candidatos ordenados con éxito!",
    },
    FORM_SUCCESS_CREATE_PROJECT: {
        EN: "Project successfully created!",
        ES: "¡Proyecto creado con éxito!",
    },
    FORM_SUCCESS_UPDATE_PROJECT: {
        EN: "Project successfully updated!",
        ES: "¡Proyecto actualizado con éxito!",
    },
    FORM_SUCCESS_CANCEL_INVITATION: {
        EN: "Invitation successfully cancelled!",
        ES: "¡Invitación cancelada con éxito!",
    },
    FORM_SUCCESS_REMOVE_COMPANY_USER: {
        EN: "User successfully removed!",
        ES: "¡Usuario eliminado con éxito!",
    },
    FORM_SUCCESS_REMOVE_COMPANY_USERS: {
        EN: "Users successfully removed!",
        ES: "¡Usuarios eliminados con éxito!",
    },
    FORM_SUCCESS_UPDATE_COMPANY_DETAILS: {
        EN: "Company details successfully updated!",
        ES: "¡Detalles de la compañía actualizados con éxito!",
    },
    FORM_SUCCESS_RESEND_COMPANY_INVITATIONS: {
        EN: "Invitations successfully resent!",
        ES: "¡Invitaciones reenviadas con éxito!",
    },
    FORM_SUCCESS_RESEND_COMPANY_INVITATION: {
        EN: "Invitation successfully resent!",
        ES: "¡Invitación reenviada con éxito!",
    },
    FORM_SUCCESS_DELETE_COMPANY: {
        EN: "Company successfully deleted!",
        ES: "¡Compañía eliminada con éxito!",
    },
    FORM_SUCCESS_UPDATE_COMPANY_USER_ROLE_RELATIONSHIP: {
        EN: "User's role successfully updated!",
        ES: "¡El rol del usuario se actualizó con éxito!",
    },
    FORM_SUCCESS_FORM_SUBMISSION: {
        EN: "Form successfully submitted!",
        ES: "¡Formulario enviado con éxito!",
    },
    FORM_SUCCESS_DELETE_FORM_SUBMISSION: {
        EN: "Submission successfully deleted!",
        ES: "¡Envío eliminado con éxito!",
    },
    FORM_SUCCESS_RESEND_BULLETIN_POST_NOTIFICATIONS: {
        EN: "Reminders have been sent!",
        ES: "¡Los recordatorios han sido enviados!",
    },
    FORM_SUCCESS_SAVE_TEMPLATE: {
        EN: "Template successfully saved!",
        ES: "¡Plantilla guardada con éxito!",
    },
    FORM_SUCCESS_DELETE_TEMPLATE: {
        EN: "Template successfully deleted!",
        ES: "¡Plantilla eliminada con éxito!",
    },
    FORM_SUCCESS_UPDATE_TEMPLATE: {
        EN: "Template successfully updated!",
        ES: "¡Plantilla actualizada con éxito!",
    },
    FORM_SUCCESS_ARCHIVE_FORM: {
        EN: "Form successfully deleted!",
        ES: "¡Formulario eliminado con éxito!",
    },
    FORM_SUCCESS_CREATE_CAMPAIGN: {
        EN: "Campaign successfully created!",
        ES: "¡Campaña creada con éxito!",
    },
    FORM_SUCCESS_UPDATE_CAMPAIGN: {
        EN: "Campaign successfully updated!",
        ES: "Campaña actualizada con éxito!",
    },
    REQUEST_REFERRALS: {
        EN: "Request referrals",
        ES: "Solicitar referencias",
    },
    REQUEST_REFERRALS_SUCCESS: {
        EN: "Referral links sent!",
        ES: "Enlaces de referencia enviados!",
    },
    JOB_EXTERNAL_LINK_TITLE: {
        EN: "If you'd like to link to a different website for the job application instead of having the applicant complete it through BoomNation, check the box and enter that URL below.",
        ES: "Si desea vincular a un sitio web diferente para la solicitud de empleo en lugar de hacer que el solicitante lo complete a través de BoomNation, marque la casilla e ingrese esa URL a abajo.",
    },
    LINK_TO_EXTERNAL_APPLICATION: {
        EN: "Link to External Application",
        ES: "Enlace a la Aplicación Externa",
    },
    HIRE_REFERRAL_SUCCESS: {
        EN: "Referral successfully hired!",
        ES: "¡Referencia contratada con éxito!",
    },
    ARCHIVE_REFERRAL_SUCCESS: {
        EN: "Referral successfully removed from project!",
        ES: "¡Referencia eliminada del proyecto con éxito!",
    },
    MARK_AS_HIRED: {
        EN: "Mark as Hired",
        ES: "Marcar como Contratado",
    },
    ARE_YOU_SURE_YOUD_LIKE_TO_MARK_THIS_REFERRAL: {
        EN: "Are you sure you'd like to mark this referral as hired?",
        ES: "¿Estás seguro de que quieres marcar esta referencia como contratada?",
    },
    THIS_WILL_ADD_THE_USER_TO_YOUR_COMPANY: {
        EN: "This will add the user to your company and this project.",
        ES: "Esto agregará el usuario a su empresa y a este proyecto.",
    },
    ARE_YOU_SURE_YOUD_LIKE_TO_REMOVE_THIS_REFERRAL: {
        EN: "Are you sure you'd like to remove this referral?",
        ES: "¿Estás seguro de que quieres eliminar esta referencia?",
    },
    THE_REFERRED_WORKER_WILL_RECEIVE: {
        EN: "The referred worker will receive an SMS letting them know they've been rejected for this project. This will also permanently remove the referral from this project.",
        ES: "El trabajador referido recibirá un SMS informándole que ha sido rechazado para este proyecto. Esto también eliminará permanentemente la referencia de este proyecto.",
    },
    REMOVE_REFERRAL: {
        EN: "Remove Referral",
        ES: "Eliminar Referencia",
    },
    BACK: {
        EN: "Back",
        ES: "Atrás",
    },
    CAMPAIGNS: {
        EN: "Campaigns",
        ES: "Campañas",
    },
    CHANGE: {
        EN: "Change",
        ES: "Cambiar",
    },
    UPLOAD: {
        EN: "Upload",
        ES: "Subir",
    },
    CAMPAIGN_FORM_DETAILS: {
        EN: "Campaign Form Details",
        ES: "Detalles del Formulario de Campaña",
    },
    CAMPAIGN_FORM_HEADER: {
        EN: "Campaign Form Header",
        ES: "Encabezado del Formulario de Campaña",
    },
    CAMPAIGN_FORM_DESCRIPTION: {
        EN: "Campaign Form Description",
        ES: "Descripción del Formulario de Campaña",
    },
    ADD_lOGO: {
        EN: "Add Logo",
        ES: "Agregar Logo",
    },
    ADD_FOOTER_IMAGE: {
        EN: "Add Footer Image",
        Es: "Agregar Imagen de Pie de Página",
    },
    CAMPAIGN_FORMS: {
        EN: "Campaign Forms",
        ES: "Formulario de Campaña",
    },
    NAME: {
        EN: "Name",
        ES: "Nombre",
    },
    CAMPAIGN_OBJECTIVE: {
        EN: "Campaign Objective",
        ES: "Objetivo de Campaña",
    },
    TARGET_AUDIENCE: {
        EN: "Target Audience",
        Es: "Público Objectivo",
    },
    CAMPAIGN_GENERAL_FORM: {
        EN: "Campaign (General Form) *",
        ES: "Campaña (Formulario General) *",
    },
    RECRUITING_FORM_DETAILS: {
        EN: "Recruiting Form Details",
        ES: "Detalles del Formulario de Reclutamiento",
    },
    RECRUITING_FORM_HEADER: {
        EN: "Recruiting Form Header",
        ES: "Encabezado del formulario de Reclutamiento",
    },
    RECRUITING_FORM_DESCRIPTION: {
        EN: "Recruiting Form Description (Job Specific Details)",
        ES: "Descripción del Formulario de Reclutamiento (Detalles Específicos del trabajo)",
    },
    RECRUITING_CAMPAIGN_FORM: {
        EN: "Recruiting Campaign Form *",
        ES: "Formulario de Campaña de Reclutamiento *",
    },
    CAMPAIGN_DESCRIPTION: {
        EN: "Campaign Description",
        ES: "Descripción de la Campaña",
    },
    TRADES: {
        EN: "Trades",
        ES: "Comercios",
    },
    CAMPAIGN_URL_SUCCESSFULY_COPIED: {
        EN: "Campaign Url successfuly copied",
        ES: "URL de la campaña copiada con éxito",
    },
    ERROR_COPYING_CAMPAIGN_URL: {
        EN: "Error copying campaign Url",
        ES: "Error al copiar la URL de la campaña",
    },
    HIRED: {
        EN: "Hired",
        ES: "Contratado",
    },
    NOT_HIRED: {
        EN: "Not Hired",
        ES: "No Contratado",
    },
    NOT_INTERESTED: {
        EN: "Not Interested",
        ES: "No Interesado",
    },
    NEED_MORE_INFO: {
        EN: "Need More Info",
        ES: "Necesita Más Información",
    },
    ACTIVATE: {
        EN: "Activate",
        ES: "Activar",
    },
    DEACTIVATE: {
        EN: "Deactivate",
        ES: "Desactivar",
    },
    CAMPAIGN_STATUS_SUCCESSFULLY_UPDATED: {
        EN: "Campaign status successfully updated",
        ES: "Estado de la campaña actualizado con éxito",
    },
    SHARE: {
        EN: "Share",
        ES: "Compartir",
    },
    CONTACTED: {
        EN: "Contacted",
        ES: "Contactado",
    },
    SENT_TO_ATS: {
        EN: "Sent to ATS",
        ES: "Enviado a ATS",
    },
    APPLIED: {
        EN: "Applied",
        ES: "Aplicado",
    },
    SENT_TO_JOB_HUNTER: {
        EN: "Sent to Job Hunter",
        ES: "Enviado a Job Hunter",
    },
    PAUSE: {
        EN: "Pause",
        ES: "Pausar",
    },
    UNLIMITED_CANDIDATES: {
        EN: "Unlimited Candidates",
        ES: "Candidatos ilimitados",
    },
    JOB_POST_BOOMNATION_COMMUNITY: {
        EN: "Job Post BoomNation Community",
        ES: "Publicación de empleo Comunidad BoomNation",
    },
    ACCESS_TO_BOOMNATION_DASHBOARD: {
        EN: "Access to BoomNation Dashboard",
        ES: "Acceso al panel de BoomNation",
    },
    CUSTOMIZED_PRE_SCREENING_RECRUITING_FORM: {
        EN: "Customized Pre Screening Recruiting Forms",
        ES: "Formularios de reclutamiento personalizados previos a la selección",
    },
    JOB_ADD_ACROSS_BOOMNATION: {
        EN: "Job Add Across BoomNation Social Media Channels",
        ES: "Agregación de empleo en los canales de redes sociales de BoomNation",
    },
    RECRUITING_SPECIALIST: {
        EN: "Recruiting Specialist",
        ES: "Especialista en reclutamiento",
    },
    CUSTOMIZED_CANDIDATE_QUALIFICATION: {
        EN: "Customized Candidate Qualification",
        ES: "Calificación de candidato personalizado",
    },
    APPLICANTS_IN_YOUR_ATS: {
        EN: "Applicants in your ATS",
        ES: "Solicitantes en su ATS",
    },
    REQUEST_QUOTE: {
        EN: "Request Quote",
        ES: "Solicitar Cotización",
    },
    RECRUITING_PER_HIRE: {
        EN: "Recruiting Per Hire",
        ES: "Reclutamiento Por Contratación",
    },
    JOB_POST_PRO: {
        EN: "Job Post Pro",
        ES: "Publicación de empleo profesional",
    },
    JOB_POST_AND_APPLICANT_PRE_SCREENING: {
        EN: "Job Post and Applicant Pre-Screening",
        ES: "Puesto de trabajo y selección previa del solicitante",
    },
    TWO_WEEK_RECRUITING_CAMPAIGN: {
        EN: "2 Week Recruiting Campaign",
        ES: "Campaña de reclutamiento de 2 semanas",
    },
    BASE_RECRUITING_CAMPAIGN: {
        EN: "Base Recruiting Campaign",
        ES: "Campaña de reclutamiento base",
    },
    PRO_RECRUITING_CAMPAIGN: {
        EN: "Pro Recruiting Campaign",
        ES: "Campaña de reclutamiento profesional",
    },
    RECRUITING_CAMPAIGN_PRO_PLUS: {
        EN: "Recruiting Campaign Pro Plus",
        ES: "Campaña de reclutamiento Pro Plus",
    },
};
