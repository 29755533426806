/* eslint-disable max-lines */
/* eslint-disable no-shadow */
import { useApolloClient } from "@apollo/client";
import wait from "waait";

import { COPY } from "~app/copyConstants";
import {
    addCompanyToCache,
    addCompanyToCompaniesCache,
    updateCompanyCache,
    removeCompanyInvitationCache,
    addToPendingCompanyInvitationsCache,
    removeFromPendingCompanyInvitationsCache,
    removeCompanyFromCompaniesCache,
} from "~graphql/cache/companies";
import {
    updateCurrentUserCompany,
    updateCompanyInUserCache,
    addCompanyToUserCache,
} from "~graphql/cache/users";
import { getSortWithKeywordSuffix } from "~graphql/helpers";
import { useCurrentCompany } from "~graphql/hooks/currentCompany";
import {
    useLocalCompanyInvitationsState,
    useLocalCompaniesState,
} from "~graphql/hooks/localEntitiesState";
import { useMutation, useQuery } from "~graphql/hooks/shared";
import { useUser } from "~graphql/hooks/users";
import {
    CREATE_COMPANY,
    UPDATE_COMPANY,
    DELETE_COMPANY,
    ACCEPT_COMPANY_INVITATION,
    INVITE_USERS_TO_COMPANY,
    RESEND_COMPANY_INVITATIONS,
    CANCEL_COMPANY_INVITATION,
    IMPORT_COMPANY_FROM_HUBSPOT,
} from "~graphql/mutations/companies";
import {
    GET_COMPANY_SUBSCRIPTION_INFO,
    GET_COMPANY,
    GET_COMPANY_INVITATIONS,
    GET_PENDING_COMPANY_INVITATIONS,
    GET_COMPANIES,
} from "~graphql/queries/companies";
import {
    GET_COMPANY_ENTITY_USERS,
    GET_COMPANY_USERS,
} from "~graphql/queries/users";
import analytics from "~services/analytics";

import {
    DEBOUNCE_KEYS,
    DEFAULT_LIMITS,
    DEFAULT_SORT,
    ENTITY_TYPES,
} from "~constants";

const SUBSCRIPTION_POLLING_RATE = 10000; // 10 seconds
const QUERY_REFETCH_WAIT_FOR_INVITES = 250; // .25 second

export const useCompany = (id) => {
    const { error, loading, data, refetch } = useQuery(GET_COMPANY, {
        variables: { id },
        skip: !id,
        notifyOnNetworkStatusChange: true,
    });
    const company = data?.getCompany;

    return { error, isLoading: loading, company, refetch };
};

export const useGetCompanies = ({ searchKeyword, rowsPerPage, page = 0 }) => {
    const offset = page * rowsPerPage;

    const { error, loading, data, refetch } = useQuery(GET_COMPANIES, {
        variables: { searchKeyword, limit: rowsPerPage, offset },
        context: { debounceKey: DEBOUNCE_KEYS.GET_COMPANIES },
        boomnation: {
            showSnackBarOnError: true,
        },
    });
    const companies = data?.getCompanies?.results;
    const total = data?.getCompanies?.meta?.total;

    return { error, isLoading: loading, companies, total, refetch };
};

export const useCreateCompany = (onCompleted) => {
    const [createCompanyMutation, { error, loading, data }] = useMutation(
        CREATE_COMPANY,
        { onCompleted }
    );

    const createCompany = (company) => {
        createCompanyMutation({
            variables: {
                company,
            },
            update: updateCachesAfterCreateCompany,
        });
    };

    return {
        createCompany,
        error,
        isLoading: loading,
        data,
    };
};

export const useImportCompanyFromHubspot = (onCompleted) => {
    const [importCompanyMutation, { error, loading }] = useMutation(
        IMPORT_COMPANY_FROM_HUBSPOT,
        { onCompleted }
    );

    const importCompany = (hubspotCompanyId) => {
        importCompanyMutation({
            variables: { hubspotCompanyId },
            update: updateCachesAfterImportCompany,
        });
    };

    return { importCompany, error, isLoading: loading };
};

export const useUpdateCompany = (onCompleted) => {
    const [mutation, { error, loading, data }] = useMutation(UPDATE_COMPANY, {
        onCompleted,
    });

    const updateCompany = ({ id, ...updatedCompany }) => {
        mutation({
            variables: {
                id,
                update: updatedCompany,
            },
            update: updateCachesAfterUpdateCompany,
            onCompleted: (data) =>
                analytics.sendCompanyUpdatedEvent(data.updateCompany),
        });
    };

    return {
        updateCompany,
        error,
        isLoading: loading,
        data,
    };
};

export const useDeleteCompany = (onCompleted) => {
    const { addDeletedCompany } = useLocalCompaniesState();

    const [mutation, { loading, error }] = useMutation(DELETE_COMPANY, {
        onCompleted,
    });

    const deleteCompany = (id) => {
        mutation({
            variables: { id },
            update(cache) {
                addDeletedCompany(id);
                removeCompanyFromCompaniesCache({ cache, id });
            },
        });
    };

    return { deleteCompany, isLoading: loading, error };
};

export function useCompanyInvitations({ code } = {}) {
    const { user } = useUser();
    const { error, loading, data, refetch } = useQuery(
        GET_COMPANY_INVITATIONS,
        {
            variables: { code },
            boomnation: {
                showSnackbarOnError: true,
            },
        }
    );

    const companyInvitations = data?.getCompanyInvitations || [];
    const hasPendingRequiredInvitation =
        !!companyInvitations.length && !user?.companies?.length;

    return {
        error,
        isLoading: loading,
        companyInvitations,
        hasPendingRequiredInvitation,
        refetch,
    };
}

export function usePendingCompanyInvitations({
    companyId,
    entityId,
    limit = DEFAULT_LIMITS.PENDING_COMPANY_INVITATIONS,
    offset = 0,
    sort = DEFAULT_SORT,
    searchKeyword = null,
}) {
    const KEYWORD_COLUMNS = ["name", "phone", "email", "role"];

    const sortForSearch = getSortWithKeywordSuffix({
        keywordColumns: KEYWORD_COLUMNS,
        hasSearchKeyword: !!searchKeyword,
        sort,
    });

    const { error, loading, data, refetch } = useQuery(
        GET_PENDING_COMPANY_INVITATIONS,
        {
            variables: {
                companyId,
                entityId,
                limit,
                offset,
                sort: sortForSearch,
                searchKeyword,
            },
            skip: !companyId,
            notifyOnNetworkStatusChange: true,
        }
    );

    const pendingInvitations = data?.getPendingCompanyInvitations?.results;
    const total = data?.getPendingCompanyInvitations?.meta?.total;

    return { error, isLoading: loading, pendingInvitations, total, refetch };
}

export function useAcceptCompanyInvitation({ onCompleted, code }) {
    const [acceptCompanyInvitationMutation, { error, loading, data }] =
        useMutation(ACCEPT_COMPANY_INVITATION, {
            onCompleted,
        });

    const acceptCompanyInvitation = ({ invitationId }) => {
        acceptCompanyInvitationMutation({
            variables: {
                invitationId,
            },
            update(cache, { data }) {
                updateCachesAfterInviteAcceptance({
                    cache,
                    invitationId,
                    company: data.acceptCompanyInvitation,
                    code,
                });
            },
            onCompleted: (data) =>
                analytics.sendAcceptedCompanyInvitationEvent(
                    data.acceptCompanyInvitation
                ),
        });
    };

    return {
        acceptCompanyInvitation,
        error,
        isLoading: loading,
        data,
    };
}

export function useCancelCompanyInvitation(onCompleted) {
    const { addDeletedCompanyInvitation } = useLocalCompanyInvitationsState();

    const [cancelCompanyInvitationMutation, { error, loading, data }] =
        useMutation(CANCEL_COMPANY_INVITATION, {
            onCompleted,
        });

    const cancelCompanyInvitation = ({ invitationId, entityId }) => {
        cancelCompanyInvitationMutation({
            variables: {
                invitationId,
            },
            update(cache, { data }) {
                removeFromPendingCompanyInvitationsCache({
                    cache,
                    entityId,
                    invitation: data.cancelCompanyInvitation,
                });
            },
            onCompleted: () => addDeletedCompanyInvitation(invitationId),
        });
    };

    return { cancelCompanyInvitation, error, isLoading: loading, data };
}

// Allows a companyId to be passed to override currentCompanyId. This is done
// to support internal admin inviting users to a company.
export function useInviteUsersToCompany({
    onCompleted,
    companyId: externalCompanyId,
    entityId,
    entityIdList,
    entityType,
}) {
    const client = useApolloClient();
    const { companyId } = useCurrentCompany(externalCompanyId);
    const [inviteUsersToCompanyMutation, { error, loading, data }] =
        useMutation(INVITE_USERS_TO_COMPANY, {
            onCompleted,
        });

    const inviteUsersToCompany = ({
        invitations,
        fromCsv = false,
        customMessage,
        shouldRemoveUnspecifiedUsers = false,
    }) => {
        inviteUsersToCompanyMutation({
            variables: {
                companyId,
                entityType,
                entityId,
                entityIdList,
                customMessage,
                invitations,
                shouldRemoveUnspecifiedUsers,
                shouldUseWelcomeUrl: true,
            },
            update(cache, { data }) {
                addToPendingCompanyInvitationsCache({
                    cache,
                    companyId,
                    entityId,
                    entityIdList,
                    invitations: data.inviteUsersToCompany,
                });
            },
            onCompleted: async () => {
                analytics.sendInvitedUsersToCompanyEvent({
                    numberInvited: invitations.length,
                    companyId,
                    entityId,
                    entityIdList,
                    entityType,
                    fromCsv,
                });
                await refetchExistingUsersAndInvites({
                    client,
                    companyId,
                    shouldRemoveUnspecifiedUsers,
                });
            },
        });
    };

    return {
        inviteUsersToCompany,
        error,
        isLoading: loading,
        data,
    };
}

// Allows a companyId to be passed to override currentCompanyId.
export function useResendCompanyInvitations({
    onCompleted,
    companyId: externalCompanyId,
    entityType,
    entityName,
}) {
    const { companyId, companyName } = useCurrentCompany(externalCompanyId);

    const [resendCompanyInvitationsMutation, { error, loading, data }] =
        useMutation(RESEND_COMPANY_INVITATIONS, { onCompleted });

    const resendCompanyInvitations = ({ invitationIds }) => {
        resendCompanyInvitationsMutation({
            variables: {
                invitationIds,
                companyId,
                entityType,
                customMessage: getCustomMessageDefaultValue({
                    entityType,
                    companyName,
                    entityName,
                }),
            },
            onCompleted: () =>
                analytics.sendInvitedUsersToCompanyEvent({
                    numberInvited: invitationIds.length,
                    companyId,
                    entityType,
                    isResend: true,
                }),
        });
    };

    return { resendCompanyInvitations, error, isLoading: loading, data };
}

function updateCachesAfterCreateCompany(cache, { data }) {
    const createdCompany = data.createCompany;

    addCompanyToUserCache({ cache, company: createdCompany });
    addCompanyToCache({ cache, createdCompany });
}

function updateCachesAfterImportCompany(cache, { data }) {
    const createdCompany = data.importCompanyFromHubspot;

    addCompanyToCompaniesCache({ cache, createdCompany });
    addCompanyToCache({ cache, createdCompany });
}

function updateCachesAfterUpdateCompany(cache, { data }) {
    const updatedCompany = data.updateCompany;

    updateCurrentUserCompany({ cache, updatedCompany });
    updateCompanyCache({ cache, updatedCompany });
}

function updateCachesAfterInviteAcceptance({
    cache,
    invitationId,
    company,
    code,
}) {
    addCompanyToUserCache({ cache, company });
    removeCompanyInvitationCache({ cache, invitationId, code });
}

// Polls company and updates subscription info in user company cache
// until the subscription is no longer expired.
export function useCurrentCompanySubscriptionPolling() {
    const { companyId, isSubscriptionActive, isLoading } = useCurrentCompany();

    const { error, loading, client } = useQuery(GET_COMPANY_SUBSCRIPTION_INFO, {
        variables: { id: companyId },
        fetchPolicy: "network-only",
        skip: isLoading || isSubscriptionActive,
        pollInterval: SUBSCRIPTION_POLLING_RATE,
        // Notify needs to be true for onCompleted to get called on
        // every poll. Otherwise it's just called the first time.
        notifyOnNetworkStatusChange: true,
        onCompleted: (data) => {
            const subscriptionInfo = data.getCompany;

            updateCompanyInUserCache({
                cache: client.cache,
                data: subscriptionInfo,
                companyId,
            });
        },
    });

    return { error, isLoading: loading };
}

export function getCustomMessageDefaultValue({
    entityType,
    entityName,
    companyName,
}) {
    if (entityType === ENTITY_TYPES.PROJECTS) {
        return `${COPY.YOU_HAVE_BEEN_INVITED_TO.EN} ${entityName}`;
    }

    return `${COPY.YOU_HAVE_BEEN_INVITED_TO.EN} ${companyName}`;
}

export async function refetchExistingUsersAndInvites({
    client,
    shouldRemoveUnspecifiedUsers,
}) {
    // only refetch these queries when users are removed
    if (!shouldRemoveUnspecifiedUsers) return;

    await wait(QUERY_REFETCH_WAIT_FOR_INVITES);
    await client.refetchQueries({
        include: [
            GET_PENDING_COMPANY_INVITATIONS,
            GET_COMPANY_USERS,
            GET_COMPANY_ENTITY_USERS,
        ],
    });
}
